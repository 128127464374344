function placesReady() {
  (function ($) {
    var i = 0;

    /**
     * google places autocomplete
     */

    $('[data-name="visitor_query"]').each(function (index) {
      var $this = $(this);
      var $form = $this.closest("form");
      var $inputWrap = $this.closest(".input-wrap");

      console.log("postcode.lookup.js callback called");

      var options = {
        // bounds: defaultBounds,
        // types: ['establishment'],
        geocode: true,
        componentRestrictions: { country: "uk" },
      };

      autocomplete = new google.maps.places.Autocomplete($this[0], options);

      autocomplete.addListener("place_changed", function () {
        var place = this.getPlace();
        console.log("place", place);

        // if the user presses enter, the below returns an error as place
        // only returns {name: 'USER_INPUT'}. therefore add a check here
        // to ensure other keys have been set
        if (Object.keys(place).length > 1) {
          $form
            .find('[data-name="visitor_lat"]')
            .val(place.geometry.location.lat());
          $form
            .find('[data-name="visitor_lng"]')
            .val(place.geometry.location.lng());
          $form
            .find('[data-name="place_auto"]')
            .val($form.find('[data-name="visitor_query"]').val());

          // check the address data for a postcode
          var postCode = "";
          for (var i = 0; i < place.address_components.length; i++) {
            var addressType = place.address_components[i].types[0];
            if (addressType == "postal_code") {
              postCode = place.address_components[i].long_name;
              console.log("postCode1", postCode);
            }
          }

          // google didn't return the postcode - so make a reverse geo lookup
          if (postCode == "") {
            console.log("reverse geo");
            var geocoder = new google.maps.Geocoder();

            geocoder.geocode(
              { location: place.geometry.location },
              function (results, status) {
                if (status === "OK") {
                  if (results[0]) {
                    for (
                      var i = 0;
                      i < results[0].address_components.length;
                      i++
                    ) {
                      var addressType =
                        results[0].address_components[i].types[0];
                      if (addressType == "postal_code") {
                        postCode = results[0].address_components[i].long_name;
                        console.log("postCode2", postCode);
                        $form.find('[name="postcode"]').val(postCode);

                        $form.find("select").attr("name", "").val("");
                        // save as a cookie
                        Cookies.set("site", $form.find('[name="site"]').val(), {
                          expires: 365,
                        });

                        $form.addClass("has-status");
                        //submit form here
                        $form.submit();
                      }
                    }
                  } else {
                    console.log("No results found");
                  }
                } else {
                  console.log("Geocoder failed due to: " + status);
                }
              }
            );
          } else {
            $form.find('[name="postcode"]').val(postCode);
          }

          var postcodeLookupObj = JSON.parse(siteData);

          var closestSite = findClosestSite(
            place.geometry.location.lat(),
            place.geometry.location.lng(),
            postcodeLookupObj.sites
          );
          console.log("closestSite", closestSite);
          $form.find('input[name="site"]').val(closestSite);
          $inputWrap.removeClass("not-valid");

          // run space estimator check and handle accordingly
          return space_estimator_redirect($form);
        }
      });

      $form.submit(function (event) {
        if (
          $form.find("select").length == 1 &&
          $form.find("select").val() !== ""
        ) {
          // there's a select list that has a value
          // the form will submit - remove the site input field name
          $form.find(".hidden-site-input").attr("name", "").val("");

          // save as a cookie
          Cookies.set("site", $form.find('[name="site"]').val(), {
            expires: 365,
          });

          // run space estimator check and handle accordingly
          return space_estimator_redirect($form);
        } else if (
          $form.find('[data-name="visitor_query"]').val() !== "" &&
          $form.find('[data-name="visitor_query"]').val() ==
            $form.find('[data-name="place_auto"]').val()
        ) {
          // google auto complete is completed
          console.log("match");

          // form will submit
          $form.find("select").attr("name", "").val("");

          // save as a cookie
          Cookies.set("site", $form.find('[name="site"]').val(), {
            expires: 365,
          });

          // run space estimator check and handle accordingly
          return space_estimator_redirect($form);
        } else {
          // prevent form submitting while we wait to check
          event.preventDefault();
          // try and determine postcode. if fail, revert to original functionality
          if (!determineAddress($this, $form, $inputWrap)) {
            // text input is empty
            // text input doesnt match google suggested
            console.log("mis match or empty");
            //console.log('Value is: ' + $form.find('[name="site"]').val());
            $inputWrap.addClass("not-valid");

            setTimeout(function () {
              if (!$form.hasClass("has-status")) {
                $("#reveal-nearest-store").foundation("open");
              }
            }, 500);
          }
        }
      });

      $form.on("change", "[name=site]", function () {
        // run space estimator check and handle accordingly
        return space_estimator_redirect($form);
      });

      i = i + 1;
    });

    function space_estimator_redirect(form) {
      // first determine if this in a modal triggered by the space estimator
      let from_space_estimator = form.closest("#reveal-nearest-store");
      from_space_estimator =
        from_space_estimator.length === 1 &&
        from_space_estimator.data("from-space-estimator") != null
          ? true
          : false;

      // if it did originate from the space estimator, we instead redirect them
      // using JS, rather than from submitted via get method
      if (from_space_estimator) {
        let redirect_url;

        // get target site
        // there's currently 2 "site" fields and the select takes priority
        // in the original code, so mimic that functionality
        let target_site = form.find('select[name="site"]');
        target_site =
          target_site.length === 1 && target_site.val() !== ""
            ? target_site.val()
            : form.find('input[name="site"]').val();

        // get various info on selection
        let selection = $(".unit-size__main-slider__carousel-cell.is-selected");
        let sizecode = selection.data("sizecode");
        let product_id = selection.data("for-size");
        let is_match = selection.is("[data-sites~=" + target_site + "]")
          ? 1
          : 0;

        // redirect
        redirect_url = `/storage/space-estimator/process?site=${target_site}&sizecode=${sizecode}&quote_product=${product_id}&is_match=${is_match}`;
        document.location.href = redirect_url;

        return false;
      } else {
        form.unbind("submit").submit();
        return true;
      }
    }

    /**
     * functoin to check for postcode match on submit, when the visitor hasn't selected one from autocomplete
     */
    function determineAddress(el, form, inputWrap) {
      console.log("determine address");
      // get entered value
      var fieldVal = el.val(),
        postcode = "";

      // if not empty
      if (fieldVal != "") {
        // initialise geocoder, and search based on their input data
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode(
          {
            address: fieldVal,
            componentRestrictions: {
              country: "uk",
            },
          },
          function (results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
              // if result found
              if (results[0]) {
                for (var i = 0; i < results[0].address_components.length; i++) {
                  var addressType = results[0].address_components[i].types[0];
                  if (addressType == "postal_code") {
                    postCode = results[0].address_components[i].long_name;
                    console.log("postCode3", postCode);
                    form.find('[name="postcode"]').val(postCode);

                    var lat = results[0].geometry.location.lat(),
                      lng = results[0].geometry.location.lng();

                    // set the other variables
                    form.find('[data-name="visitor_lat"]').val(lat);
                    form.find('[data-name="visitor_lng"]').val(lng);
                    form.find('[data-name="place_auto"]').val(fieldVal);

                    // find and set closest site
                    var postcodeLookupObj = JSON.parse(siteData);
                    var closestSite = findClosestSite(
                      lat,
                      lng,
                      postcodeLookupObj.sites
                    );
                    console.log("closestSite", closestSite);
                    form.find('input[name="site"]').val(closestSite);
                    inputWrap.removeClass("not-valid");
                    form.find("select").attr("name", "").val("");

                    // save as a cookie
                    Cookies.set("site", form.find('[name="site"]').val(), {
                      expires: 365,
                    });
                    form.addClass("has-status");

                    return space_estimator_redirect(form);
                  }
                }
              } else {
                console.log("No results found");
                return false;
              }
            } else {
              console.log(
                "Geocode was not successful for the following reason: " + status
              );
              return false;
            }
          }
        );
      } else {
        console.log("No value entered.");
        return false;
      }
    }

    $('[data-action="use-location"]').click(function () {
      geoLocationFinder();
    });
  })(jQuery); // Fully reference jQuery after this point.
}

/**
 * helper function to find distance between 2 points
 */

function distance(lat1, lon1, lat2, lon2) {
  var p = 0.017453292519943295; // Math.PI / 180
  var c = Math.cos;
  var a =
    0.5 -
    c((lat2 - lat1) * p) / 2 +
    (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;

  return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
}

/**
 * loop through sites to find the closest
 */

function findClosestSite(lat, lng, sitesObj, replaceHyphensWithSpaces = false) {
  console.log(sitesObj);
  var closest = "";
  var shortest = 1000;
  $.each(sitesObj, function (index, site) {
    var d = distance(site.lat, site.lng, lat, lng);
    if (d < shortest) {
      shortest = d;
      closest = site.name;
    }
  });
  return replaceHyphensWithSpaces ? closest.replaceAll("-", " ") : closest;
}

/**
 * geolocation from device api
 */

var geoLocationOptions = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

function geoLocationFinder() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      geoLocationSuccess,
      geoLocationError,
      geoLocationOptions
    );
  } else {
    alert("Error: The Geolocation service failed. Please try again.");
  }
}

function geoLocationSuccess(pos) {
  console.log(pos);
  var postcodeLookupObj = JSON.parse(siteData);
  var closestSite = findClosestSite(
    pos.coords.latitude,
    pos.coords.longitude,
    postcodeLookupObj.sites
  );
  Cookies.set("site", closestSite, { expires: 365 });
  var url = postcodeLookupObj.sizeselectorPermalink + "?site=" + closestSite;
  location = url;
}

function geoLocationError(err) {
  console.warn("ERROR(" + err.code + ")");
}
